import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import RichText from '~components/RichText'
import Section from '~components/Section'
import CenteredSlider from '~components/CenteredSlider'
import Seo from '~components/Seo'
import ArticleTile from '~components/ArticleTile';
import DataViewer from '~utils/DataViewer'
import { mobile } from "~styles/global"
import {
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
} from "react-share";
import Slider from '../components/Slider'
import PortraitHeader from '~components/ArticleHeader/PortraitHeader'
import LandscapeHeader from '~components/ArticleHeader/LandscapeHeader'

const Article = ({ pageContext, data }) => {
  const { title, content, datePublished, slug } = data.sanityArticle
  const [currentUrl, setCurrentUrl] = useState(null)

  useEffect(()=> {
    setCurrentUrl(window.location.href)
  }, [])

  return(
    <>
      <Seo 
        title={title}
        metaTitle={content?.seo?.metaTitle}
        description={content?.seo?.metaDescription}
        image={content?.seo?.socialImage}
      />
			{content.headerLayout === `portrait` &&
				<PortraitHeader content={content} title={title} datePublished={datePublished}/>
			}
			{content.headerLayout === `landscape` &&
				<LandscapeHeader content={content} title={title} datePublished={datePublished}/>
			}
      <ModuleStyles className='module-styles'>
        <RichText content={content.text} contained={true} />
      </ModuleStyles>
      <ShareSection >  
        <div>
          <h5 className="highlight-s">Share the story:</h5>
          <div>
            <FacebookShareButton url={currentUrl}>
              <SocialLink className="caps">Facebook</SocialLink>  
            </FacebookShareButton>
            <TwitterShareButton url={currentUrl}>
              <SocialLink className="caps">Twitter</SocialLink>  
            </TwitterShareButton>
            <PinterestShareButton url={currentUrl} media="https://cdn.sanity.io/images/j9foxki4/production/92ea4af078daf86115e8095eb09ea0a0fefa9403-1080x1080.jpg">
              <SocialLink className="caps">Pinterest</SocialLink>  
            </PinterestShareButton>
            <a href={"mailto:?subject=" + title + "&body=" + currentUrl + slug.current}>
              <SocialLink className="caps" css={css`:after{content: '';}`}>Email</SocialLink> 
            </a>
          </div>
        </div>
      </ShareSection>
      {content.brandsMentioned.length > 0 && 
        <BrandsSlider brandsSlider slides={content.brandsMentioned}/>
      }
      { content.relatedArticles.length > 0 &&
      <RelatedArticles>
        <RelatedHeader>
          <div>
            <Action className="caps">Related</Action>
            <Title><span>/</span>Articles</Title>
          </div>
        </RelatedHeader>
        <RelatedGrid>
          {content.relatedArticles.map(article => (
            <React.Fragment key={article._key ? article._key : article.title}>
            <ArticleTile 
              content={article} 
              css={css`
                ${mobile} {
                  margin-bottom: 25px;
                }
              `}
            />
          </React.Fragment>
          ))}
        </RelatedGrid>
      </RelatedArticles>
      }
      <DataViewer data={data} name="content"/>
    </>
  )
}

const ModuleStyles = styled.div`
  & > div > p, 
  & > div > h2, 
  & > div > h4,
	& > div > h3, 
  & > div > h6 {
    width: calc(60% - 10px);
    margin-left: auto;
    margin-right: auto;
    max-width: 655px;
    padding: 0 40px;
    box-sizing: border-box;
    ${mobile}{
      width: 100%;
      max-width: 100%;
      padding: 0 15px;
    }
  }
  & > div > p {
    margin-bottom: 30px;
    ${mobile}{
      margin-bottom: 22px;
    }
  }
  & h2 {
    margin-bottom: 32px;
    ${mobile}{
      margin-bottom: 22px;
    }
  }
  & h4 {
    margin-bottom: 26px;
    ${mobile}{
      margin-bottom: 22px;
    }
  }
`
const ShareSection = styled(Section)`
  margin-top: 100px;
  background-color: var(--yellow);
  padding: 4px 0;
  ${mobile}{
    margin-top: 40px;
    }
  > div >div{
    grid-column: 1/13;
    justify-content: center;
    align-items: center;
    display: flex;
    ${mobile}{
      flex-wrap: wrap;
    }
  }
  h5{
    margin-right: 5px;
    text-align: center;
    ${mobile}{
      width: 100%;
    }
  }
`
const SocialLink = styled.span`
  margin-right: 9px;
  :after{
    content: '/';
    margin-left: 9px;
  }
`
const BrandsSlider = styled(CenteredSlider)`
  margin-top: 91px;
  margin-bottom: 94px;
  ${mobile}{ 
    margin-top: 45px;
    margin-bottom: 33px;
  }
`
const RelatedArticles = styled(Section)`
  background-color: var(--off-white);
  padding-top: 80px;
  padding-bottom: 100px;
  ${mobile}{
    padding-top: 38px;
    padding-bottom: 12px;
  }
`
const RelatedGrid= styled.div`
  grid-column: span 12;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 40px;
  ${mobile}{
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 15px;
  }
`
const RelatedHeader = styled.div`
  grid-column: span 12;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px;
  ${mobile}{
    margin-bottom: 24px;
    flex-wrap: wrap;
  }
`
const Action = styled.span`
  vertical-align: top;
  display: inline-block;
  margin-right: 7px;
  font-weight: 600;
`
const Title = styled.h3`
  line-height: 1;
  display: inline-block;
  > span{
    display: inline-block;
    margin-right: 8px
  }
`
export const query = graphql`
  query ArticleQuery($slug: String) {
    sanityArticle(slug: {current: {eq: $slug}}) {
      title
      datePublished(formatString: "D MMMM YYYY")
      slug {
        current
      }
      content {
        authors{
          name
        }
        column {
          title
          slug {
            current
          }
        }
        headerLayout
        headerTextColor
        introText: _rawIntroText
        headerImage{
          ...imageWithCaption
        }
        mobileHeaderImage{
          ...imageWithCaption
        }
        text: _rawText(resolveReferences: {maxDepth: 10})
        brandsMentioned{
          ...brandThumb
        }
        relatedArticles {
          ...articleThumb
        }
        seo{
          ...seo
        }
      }
    }
  }
`

export default Article