import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import RichText from '~components/RichText'
import Image from '~components/Image'
import ParallaxImage from '~components/ParallaxImage'
import { mobile, tablet, desktop } from "~styles/global"
import Section from '~components/Section'

const PortraitHeader = ({ content, title, datePublished }) => {
	return (
		<>
			<Header css={css`
		`}>
			<HeaderPanel>
				<h6 className="caps" css={css`
				margin-top:42px;
				${mobile}{margin-top: 30px;}`}>{content.column?.title}</h6>
				<div 
					css={css`
						align-self: center;
						margin-bottom: 0;
						${mobile}{
							margin-bottom: 0;
						}
				`}>
					<h1 
						className='h2' 
						css={css`
							grid-column: 1/6;
							${tablet}{
								grid-column: 1/7;
							}
							${mobile}{
								grid-column: 1/6;
							}
					`}>{title}</h1>
					<HeaderDetails className="highlight-s">
						<span className="caps">Words</span>
						{content.authors.map(author => (
							<span key={author.name}> {author.name}</span>
						))}
						<span className="caps">Date</span>
						<span> {datePublished}</span>
					</HeaderDetails>
					{content.headerLayout === `portrait` && 
					<Intro>
						<h6><RichText content={content.introText} /></h6>
					</Intro>
					}
				</div>  
			</HeaderPanel>
			<HeaderImage 
				id={content?.headerImage?.asset?._id}  
				alt={content?.headerImage?.alt} 
				aspectRatio={1}
				css={css`
					grid-column: 7/13;
					${mobile} {
						grid-row: 2;
						display: none;
					}
			`}/>
			<MobileHeaderImage
				image={content?.mobileHeaderImage?.asset ? content?.mobileHeaderImage : content?.headerImage}  
				cover
				css={css`
					display: none;
					${mobile} {
						display: block;
						grid-row: 2;
					}
			`}/>
		</Header>
		<PortraitCaptionSection>
			<PortraitCaption 
			css={css`padding-top: ${content.headerImage?.caption ? `12px` : `0`};
			`}>
				<span className="highlight-s">
					<RichText content={content.headerImage?.caption} />
				</span>
			</PortraitCaption>
		</PortraitCaptionSection>
		</>
	)
}

const Intro = styled.div`
	grid-column: 2 / 6;
	margin-bottom: 46px;
`

const Header = styled(Section)`
  border-bottom: 1px solid var(--black);
  > div{
    min-height: calc(100vh - 50px);
    /* max-height: 62.5vw; */
    ${mobile}{
      height: auto;
    }
  }
`

const HeaderPanel = styled.div`
  grid-column: 1/6;
  grid-row: 1;
  display: grid;
  z-index: 2;
  align-items: center;
  grid-template-rows: max-content 1fr;
  >div {
		max-height: 100%;
    margin-top: 38px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 40px;
    h1{
      margin-bottom: 10px;
      ${mobile}{
        margin-bottom: 7px;
      }
    }
  }
  ${tablet}{
    >div{
      margin-top: 25px;
    }
  }
  ${mobile}{
    grid-column: 1/13;
  }
`

const HeaderDetails = styled.h6`
  grid-column: 1/6;
  margin-bottom: 58px;
  span{
    margin-right: 10px;
    &.caps{
      margin-right: 2px;
    }
  }
  p {
    display: inline;
  
  }
  ${desktop}{
    margin-bottom: 36px;
  }
  ${tablet}{
    grid-column: 1/7;
  }
  ${mobile}{
    grid-column: 1/6;
  }
`

const HeaderImage = styled(ParallaxImage)`
  margin-right: -40px;
  margin-left: -40px;
  grid-row: 1;
  .gatsby-image-wrapper {
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  ${mobile}{
    margin-right: -15px;
    margin-left: -15px;
    grid-column: 1/13;
  }
`
const MobileHeaderImage = styled(Image)`
  grid-column: 1/13;
  margin-right: -15px;
  margin-left: -15px;
  overflow: hidden;
  max-height: 120vw;
  .gatsby-image-wrapper {
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`
const PortraitCaptionSection = styled(Section)`
  margin-bottom: 100px;
  ${mobile}{
    margin-bottom: 38px;
  }
`
const PortraitCaption = styled.div`
  margin-left: -40px;
  grid-column: 7/13;
  ${mobile}{
    margin-left: 0px;
    grid-column: span 12;
  }
`

PortraitHeader.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
	title: PropTypes.string,
	datePublished: PropTypes.string,
}

export default PortraitHeader